import React from "react";
import { Box, Container, Flex, Text, Grid, GridItem } from "@chakra-ui/react";
import NavbarBottom from "../layout/NavbarBottom";
import { useAuth } from "../store/AuthContext";
import Navbar from "../layout/Navbar";

function About() {
  const { loggedIn } = useAuth();
  return (
    <>
      <Navbar />
      <Box mb={120} backgroundColor="white">
        <div>
          <Flex>
            <Box position="absolute" top="2" ml={2}>
              <i className="fas fa-angle-left yön"></i>
            </Box>
          </Flex>
        </div>

        <Grid
          h="100%"
          templateRows="repeat(1, 1fr)"
          templateColumns="repeat(1, 1fr)"
          gap={10}
        >
          <Container>
            <GridItem mt={5} rowSpan={4}>
              {/* Rest of the code remains unchanged */}
            </GridItem>
          </Container>
        </Grid>

        <Text mt="30" fontSize="14px" color="#888" textAlign="center">
          Tüm hakları saklıdır. IThink Software Company &copy; 2023
        </Text>
        {loggedIn && <NavbarBottom />}
      </Box>
    </>
  );
}

export default About;
