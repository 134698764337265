import {
  Alert,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Container,
  Text,
  useTheme,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { useBasket } from "../store/BasketContext";
import NavbarBottom from "../layout/NavbarBottom";
import { useTranslation } from "react-i18next";
import Navbar from "../layout/Navbar";
import { useSettings } from "../store/SettingsContext";

function Basket() {
  const theme = useTheme();
  const { orderStatus } = useSettings();
  const { basket, increase, decrease, setConfirm } = useBasket();
  const { t } = useTranslation();
  const total = basket
    .reduce(
      (total, product) => (total = total + product.price * product.count),
      0
    )
    .toFixed(2);
  const { getLangText } = useSettings();
  const handleSubmitForm = async () => {
    setConfirm(basket);
  };

  return (
    <Box>
      <Navbar />
      <Link to="/">
        <Flex>
          <Box position="absolute" top="2" ml={2}>
            <i className="fas fa-angle-left yön"></i>
          </Box>
        </Flex>
      </Link>
      <Box mt={5} textAlign="center">
        <Text
          fontWeight="semibold"
          textAlign="center"
          fontSize={30}
          color="teal.500"
        >
          {t("Sipariş Listem")}
        </Text>
      </Box>

      <Box mb={150} mt={3} bgColor="gray.50">
        {basket.length < 1 && (
          <Container>
            <Alert status="warning">
              {t("Sipariş Listesinde Ürün Bulunmuyor")}
            </Alert>
          </Container>
        )}

        {basket.length > 0 && (
          <>
            {basket.map((product, i) => {
              return (
                <Container key={i}>
                  <Box mb={5}>
                    <Grid
                      h="100%"
                      width="100%"
                      bgColor="white"
                      borderRadius={12}
                      templateRows="repeat(3, .5fr)"
                      templateColumns="repeat(4, 1fr)"
                      gap={2}
                      key={product.id}
                      boxShadow="2xl"
                    >
                      <GridItem mt={1} p={3} rowSpan={1} colSpan={3}>
                        <Text color="black" fontWeight="bold">
                          {getLangText(product.title)}
                        </Text>
                      </GridItem>
                      <GridItem rowSpan={1} colSpan={1} alignSelf="end">
                        <Box
                          mx="auto"
                          borderRadius={10}
                          border="3px solid teal"
                          width="100px"
                          textAlign="center"
                          color="teal"
                          fontSize={18}
                          fontWeight="bold"
                        >
                          {(product.price * product.count).toFixed(2)} ₺
                        </Box>
                      </GridItem>
                      <GridItem mt={3} px={3} pb={1} rowSpan={3} colSpan={3}>
                        <Box fontSize={13} color="gray">
                          <Text>
                            {t("Çıkarılacak Malzeme Tercihi")} :{" "}
                            {product.issuedContent?.toString()}
                          </Text>

                          <Text>
                            {t("Not :")} {product.noteDetail}
                          </Text>
                        </Box>
                      </GridItem>
                      <GridItem rowSpan={2} colSpan={1} alignSelf="center">
                        <Box w="160px" textAlign="center">
                          <Button
                            size="sm"
                            boxShadow="dark-lg"
                            colorScheme="white"
                            color="teal"
                            borderRadius={false}
                            borderLeftRadius={12}
                            onClick={() =>
                              decrease(
                                product.id,
                                product.noteDetail,
                                product.issuedContent
                              )
                            }
                          >
                            <i className="fa-solid fa-minus"></i>
                          </Button>
                          <Button
                            size="sm"
                            borderRadius={false}
                            bgGradient={theme.colors.ithinkGradient}
                            _hover={{ bgColor: theme.colors.ithinkGradient }}
                            background={theme.colors.ithinkGradient}
                            color="white"
                          >
                            {product.count}
                          </Button>
                          <Button
                            size="sm"
                            boxShadow="dark-lg"
                            colorScheme="white"
                            color="teal"
                            borderRadius={false}
                            borderRightRadius={12}
                            onClick={() =>
                              increase(
                                product.id,
                                product.noteDetail,
                                product.issuedContent
                              )
                            }
                          >
                            <i className="fa-solid fa-plus"></i>
                          </Button>
                        </Box>
                      </GridItem>
                    </Grid>
                  </Box>
                </Container>
              );
            })}
          </>
        )}
      </Box>
      {orderStatus && (
        <Box
          position="fixed"
          bottom="12"
          width="100%"
          bgColor="white"
          boxShadow="lg"
          p={3}
        >
          <Container>
            <Link to="/orderconfirm">
              <Button
                onClick={handleSubmitForm}
                disabled={basket.length < 1}
                height="50px"
                width="65%"
                borderRadius={false}
                borderLeftRadius={12}
                color="white"
                bgGradient={theme.colors.ithinkGradient}
                _hover={{ bgColor: theme.colors.ithinkGradient }}
                background={theme.colors.ithinkGradient}
              >
                {t("Sipariş Ver")}
              </Button>
            </Link>
            <Button
              disabled={basket.length < 1}
              fontSize="lg"
              fontWeight="bold"
              height="50px"
              boxShadow="lg"
              width="35%"
              borderRadius={false}
              borderRightRadius={12}
              color="teal"
              colorScheme="white"
            >
              {total} ₺
            </Button>
          </Container>
        </Box>
      )}

      <NavbarBottom />
    </Box>
  );
}

export default Basket;
