import React from "react";
import "../css/NavbarBottom.css";
import { Link } from "react-router-dom";
import { useBasket } from "../store/BasketContext";
import { useTranslation } from "react-i18next";
import { useSettings } from "../store/SettingsContext";

function NavbarBottom() {
  const { basket } = useBasket();
  const { t } = useTranslation();

  const { waiterRequestStatus, orderStatus } = useSettings();

  return (
    <nav className="nav">
      <Link to="/" className="nav__link">
        <i className="material-icons nav__icon">home</i>
        <span className="nav__text">{t("Anasayfa")}</span>
      </Link>
      <Link to="/categories" className="nav__link">
        <i className="material-icons nav__icon">dashboard</i>
        <span className="nav__text">{t("Menü")}</span>
      </Link>
      {waiterRequestStatus ? (
        <Link to="/waiter_calling" className="nav__link">
          <i className="material-icons nav__icon">person</i>
          <span className="nav__text">{t("Garson")}</span>
        </Link>
      ) : (
        <div className="nav__link disabled">
          <i className="material-icons nav__icon">person</i>
          <span className="nav__text">{t("Garson")}</span>
        </div>
      )}

      <Link to="/basket" className="nav__link position-relative">
        <i className="material-icons nav__icon">shopping_basket</i>
        <span className="position-absolute top-0 basketcss translate-middle badge rounded-pill mt-2">
          {basket.length}
        </span>
        <span className="nav__text">{t("Sepet")}</span>
      </Link>
      {orderStatus ? (
        <Link to="/account" className="nav__link">
          <i className="material-icons nav__icon">assignment</i>
          <span className="nav__text">{t("Hesap")}</span>
        </Link>
      ) : (
        <div className="nav__link disabled">
          <i className="material-icons nav__icon">assignment</i>
          <span className="nav__text">{t("Hesap")}</span>
        </div>
      )}
    </nav>
  );
}

export default NavbarBottom;
