import { Box, Flex, Container } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { useMenu } from "../store/MenuContext";
import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import NavbarBottom from "../layout/NavbarBottom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useBasket } from "../store/BasketContext";
import { fetchProductList, fetchCategory } from "../api/api";
import "../css/App.css";
import SideCategories from "../components/SideCategories";
import ProductCard from "../components/ProductCard";
import Navbar from "../layout/Navbar";
import Loading from "../components/Loading";
import Category from "../components/Category";

import { useSettings } from "../store/SettingsContext";

function Product() {
  const { categoryId } = useParams();
  const { products, setProducts } = useMenu();

  const { addToBasket } = useBasket();
  const [selectedCategory, setSelectedCategory] = useState();
  const [loadingCategory, setLoadingCategory] = useState(false);
  const { getLangText } = useSettings();

  useEffect(() => {
    (async () => {
      try {
        setLoadingCategory(true);
        const category = await fetchCategory(categoryId);
        setLoadingCategory(false);
        setSelectedCategory(category);
      } catch (e) {
        setLoadingCategory(false);
      }
    })();
  }, [categoryId]);

  const notify = () =>
    toast("Ürün Sepete Eklendi!", {
      hideProgressBar: true,
      position: "top-left",
    });

  const { isLoading, error, data } = useQuery(["product", categoryId], () =>
    fetchProductList(categoryId)
  );

  useEffect(() => {
    setProducts(data);
  }, [data, setProducts]);

  const addBasketAll = (e, item) => {
    e.preventDefault();
    const basketNote = "";
    const checkedValues = "";
    if (
      getLangText(item.description) === null &&
      getLangText(item.allergen) === null &&
      item.quantity === null &&
      getLangText(item.removed_material) === null
    ) {
      addToBasket(item, basketNote, checkedValues);
      notify();
    }
  };

  if (isLoading || loadingCategory) return <Loading height="100vh" />;
  if (error) return `Bir hata oluştu: ${error.message}`;

  return (
    <Box>
      <Navbar />
      <Link to="/categories">
        <Flex>
          <Box position="absolute" top="2" ml={2}>
            <i className="fas fa-angle-left yön"></i>
          </Box>
        </Flex>
      </Link>

      <SideCategories />

      <Container mb={100}>
        {selectedCategory?.subCategories
          ? selectedCategory.subCategories.map((item) => (
              <Category key={item.id} item={item} />
            ))
          : products?.map((product) => (
              <ProductCard
                key={product.id}
                item={product}
                addBasketAll={addBasketAll}
              />
            ))}
        <ToastContainer autoClose={2000} />
      </Container>
      <NavbarBottom />
    </Box>
  );
}

export default Product;
