import { Box, Image } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import logo from "../logo/cafe_logo.png";

function IThinkNavbar() {
  return (
    <Box>
      <Box mt={5}>
        <Link to="/">
          <Box backgroundColor="white" display="flex" justifyContent="center">
            <Image
              borderRadius="full"
              boxSize="100px"
              src={logo}
              alt="menulogo"
            />
          </Box>
        </Link>
      </Box>
    </Box>
  );
}

export default IThinkNavbar;
