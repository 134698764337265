import { Box, Button, ButtonGroup } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { useSettings } from "../store/SettingsContext";
const Category = ({ item }) => {
  const { getLangText } = useSettings();

  return (
    <Box>
      <Link to={`/product/${item.id}`}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          py={12}
          bgPosition="center"
          bgSize="cover"
          bgRepeat="no-repeat"
          my={5}
          backgroundColor="gray.200"
          _hover={{ opacity: 0.9 }}
          backgroundImage={`data:image/jpeg;base64,${item.image}`}
          backgroundSize="100% 100%"
          borderRadius={15}
          fontWeight="bold"
          color="white"
          position="relative"
        >
          <ButtonGroup gap="4">
            <Button colorScheme="blackAlpha">{getLangText(item.title)}</Button>
          </ButtonGroup>
        </Box>
      </Link>
    </Box>
  );
};

export default Category;
