import React from "react";

import { Box, Text } from "@chakra-ui/react";
import NavbarBottom from "../layout/NavbarBottom";
import { useAuth } from "../store/AuthContext";
import IThinkNavbar from "../layout/IThinkNavbar";

function PrivacyPolicy() {
  const { loggedIn } = useAuth();

  return (
    <Box p={5}>
      <IThinkNavbar />
      <Box mb={120} backgroundColor="white">
        <Box
          fontSize="26px"
          mb={10}
          color="teal"
          fontWeight="semibold"
          display="flex"
          justifyContent="center"
        >
          Gizlilik Politikası
        </Box>
        <Box fontSize="22px" fontWeight="semibold" color="teal">
          Biz Kimiz
        </Box>

        <Box color="gray">
          IThink, QR menülerin hazırlanması konusunda uzmanlaşmış bir şirkettir.
          Müşterilerinize sunduğunuz yemek seçeneklerini daha hızlı ve kolay bir
          şekilde sipariş etmelerine olanak tanıyan, kullanımı kolay bir QR menü
          çözümü sunuyoruz.
        </Box>

        <Box fontSize="22px" mt={5} fontWeight="semibold" color="teal">
          Bu Gizlilik Politikasının amacı nedir?
        </Box>

        <Box color="gray">
          Topladığımız bilgiler, hizmetlerimizi geliştirmek ve kişiselleştirmek,
          müşterilere daha iyi hizmet vermek, pazarlama faaliyetleri
          gerçekleştirmek ve yasal düzenlemelere uymak amacıyla kullanılır.
        </Box>

        <Box fontSize="22px" mt={5} fontWeight="semibold" color="teal">
          Hangi verileri topluyoruz?{" "}
        </Box>

        <Box color="gray">
          Kullanıcılarımızdan (uygulamada alınan bazı aksiyonlar sonucunda)
          sadece isim bilgileri alınmaktadır.
        </Box>

        <Box fontSize="22px" mt={5} fontWeight="semibold" color="teal">
          Topladığımız verileri nasıl kullanıyoruz?
        </Box>

        <Box color="gray">
          Topladığımız kişisel verileri müşterilerimi için daha hızlı çözümler
          amacıyla veritabanı üzerinden hatırlatmalarda bulunuyoruz.
        </Box>

        <Box fontSize="22px" mt={5} fontWeight="semibold" color="teal">
          Güvenlik önlemleri
        </Box>

        <Box color="gray">
          {` Bilgi güvenliğinde sektördeki en iyi uygulamaları takip etmekteyiz.
          Kişisel verilerinizi güvende ve emniyette tutmak amacıyla, işimizin
          büyüklüğüne ve doğasına uygun güvenlik önlemleri uyguluyoruz..
          Fiziksel güvenlik IThink, operasyonları için bulut hizmetlerini
          kullanır. IThink'ın bulunduğu fiziksel tesislere erişim sağlamak için
          bir RFID çipine ihtiyaç duyulmaktadır. Bilgi Erişimi Çalışanlar, iş
          uygulamalarında yer alan verilere yalnızca 'bilmesi gerekenler'
          temelinde erişebilir. Ayrıcalıklı kullanıcılara 'erişim ihtiyacı'
          temelinde verilir. Uç Nokta Güvenliği IThink, uç nokta güvenliği ve
          virüslere ve fidye yazılımlarına karşı koruma için Sophos'u kullanır.
          Tüm cihazlar, cihazın kaybolması veya çalınması durumunda uzaktan
          kaydırma özelliği ile şifrelenir. Kişisel verilerinizi kayıp, yetkisiz
          erişim, ifşa ve imhadan korumak için makul önlemler alsak da, İnternet
          üzerinden hiçbir aktarım yöntemi veya elektronik depolama yöntemi %100
          güvenli değildir ve bu nedenle mutlak güvenliğini garanti edemeyiz.`}
        </Box>

        <Box fontSize="22px" mt={5} fontWeight="semibold" color="teal">
          {`Haklarınız`}
        </Box>

        <Box color="gray">
          {`IThink, kişisel verileri aşağıdaki yasal dayanaklara göre işler: (i)
          IThink'ın Müşteri ile olan sözleşmeden doğan yükümlülüklerinin yerine
          getirilmesi, (ii) IThink'ın meşru menfaati ve (iii) yasalara uygunluk.
          Çoğu durumda, veri sorumlusu onlar oldukları için gizlilik haklarınızı
          kullanmak için doğrudan Müşteri ile iletişime geçmelisiniz. Bizimle
          doğrudan iletişime geçerseniz, bilgilerinizi makul bir süre içinde ve
          Müşteriye talebinizi bildirdikten sonra kaldırabilir veya
          güncelleyebiliriz. İşlememiz sizin rızanıza dayanıyorsa, rızanızı
          istediğiniz zaman geri çekme hakkına sahipsiniz.`}
        </Box>

        <Box fontSize="22px" mt={5} fontWeight="semibold" color="teal">
          {`Çerez Bildirimi`}
        </Box>

        <Box color="gray">
          {`Çerez, bilgisayarınıza kaydedilen ve bilgileri depolayan küçük bir
          metin dosyasıdır (genellikle 1 kB'den küçüktür). Çerezler, diğer
          amaçların yanı sıra kullanım için bir web sitesini geliştirmek için
          kullanılır. Web sitesi, oturum çerezleri olarak adlandırılan
          çerezlerin kolay sürümünü kullanır. Bir web sayfasında gezinirken,
          bilgi alışverişinde bulunmalarını sağlamak için bilgisayarınız ve
          sunucumuz arasında oturum çerezleri gönderilir.. Oturum çerezleri
          bilgisayarınızda saklanır. Çoğu tarayıcıda, oturum çerezlerini devre
          dışı bırakabilecek güvenlik ayarları vardır. . Web Sitemizde oturum
          çerezlerini kabul etmemeyi seçerseniz, bu, Web Sitelerinin
          özelliklerinden tam olarak yararlanmanızı engelleyebilir. Web Sitesini
          ziyaret ederken, ziyaret sıklığını ölçmek için IP adresiniz otomatik
          olarak kaydedilir ve biz veya yetkili servis sağlayıcılarımız, size
          daha iyi, daha hızlı ve daha güvenli bir deneyim sunmaya yardımcı
          olmak ve pazarlama amaçlarıyla bilgi depolamak için çerezleri
          kullanabiliriz.`}
        </Box>

        <Box fontSize="22px" mt={5} fontWeight="semibold" color="teal">
          {`Bu gizlilik politikasındaki değişiklikler`}
        </Box>

        <Box color="gray">
          {`IThink zaman zaman bu gizlilik politikasını ve çerez bildirimini
          güncelleyebilir. Bunu yaptığında, bu politikanın başlangıcında
          Yürürlük Tarihini de revize edecektir. IThink'ın kişisel verileri
          toplaması, kullanması ve ifşa etmesi hakkında bilgi sahibi olmak için
          bu gizlilik politikasını periyodik olarak gözden geçirmenizi öneririz.
          IThink, kendi takdirine bağlı olarak, gizlilik politikasını, çerez
          bildirimini ve Web Sitesini herhangi bir zamanda değiştirme, ekleme
          veya çıkarma yapma haklarını saklı tutar.`}
        </Box>

        <Box fontSize="22px" mt={5} fontWeight="semibold" color="teal">
          {`İletişim Bilgileri`}
        </Box>

        <Box color="gray">
          {`Bu gizlilik politikası hakkında herhangi bir sorunuz veya yorumunuz
          varsa, lütfen bilgi@ithinkmenu.com adresine e-posta olarak gönderiniz`}
        </Box>

        <Text mt="30" fontSize="14px" color="#888" textAlign="center">
          Tüm hakları saklıdır. IThink Software Company &copy; 2023
        </Text>
        {loggedIn && <NavbarBottom />}
      </Box>
    </Box>
  );
}

export default PrivacyPolicy;
