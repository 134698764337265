import { Box, Image } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import logo from "../logo/cafe_logo_bg.png";

function Navbar() {
  return (
    <Box >
      <Box mt={5}>
        <Link to="/">
          <Box backgroundColor="white" display="flex" justifyContent="center">
            <Image
              borderRadius="full"
              height="120px"
              rounded="full"
              src={logo}
              alt="menulogo"
            />
          </Box>
        </Link>
      </Box>
    </Box>
  );
}

export default Navbar;
